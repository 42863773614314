import * as React from "react";
import Layout from "../components/layout";
import Offers from "../components/offers";

const AngebotePage = () => {
  return (
    <Layout>
      <h2 className="text-3xl font-bold text-left mb-4">Angebote</h2>
      <Offers />
    </Layout>
  );
};

export default AngebotePage;

export const Head = () => <title>Angebote</title>;
