/* This example requires Tailwind CSS v2.0+ */

import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import { CheckIcon } from "@heroicons/react/24/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

const features = [
  {
    name: "FOH Front of House Engineer",
    description:
      "You can manage phone, email and chat conversations all from a single mailbox.",
  },
  {
    name: "Atmos Mixing",
    description:
      "You can manage phone, email and chat conversations all from a single mailbox.",
  },
  {
    name: "Mix & Mastering",
    description:
      "You can manage phone, email and chat conversations all from a single mailbox.",
  },
  {
    name: "Beats & Samples",
    description:
      "You can manage phone, email and chat conversations all from a single mailbox.",
  },
  {
    name: "Studio Sessions",
    description:
      "Find what you need with advanced filters, bulk actions, and quick views.",
  },
  {
    name: "Management ",
    description:
      "Find what you need with advanced filters, bulk actions, and quick views.",
  },
  {
    name: "Marketing & Promo",
    description:
      "Find what you need with advanced filters, bulk actions, and quick views.",
  },
  {
    name: "Foto & Video",
    description:
      "Find what you need with advanced filters, bulk actions, and quick views.",
  },
  {
    name: "Styling ",
    description:
      "Find what you need with advanced filters, bulk actions, and quick views.",
  },
  {
    name: "Coaching ",
    description:
      "Find what you need with advanced filters, bulk actions, and quick views.",
  },
];

export default function Offers() {
  return (
    <div className="bg-white">
      <p className="mb-4 text-lg text-gray-500">
        Durch unsere Partnerschaft mit PolarisRecording Studios und Hellow
        Enterpries ist SwissFranx an der Spitze des Sound Designs. Bist du schon
        etabliert in der Musik Industrie und willst auf der Bühne die gleiche
        Qualität wie im Studio?
      </p>
      <div className="flex">
        <a
          href="https://joe.studio/"
          target="_blank"
          className="text-center  text-yellow-500 p-2 mb-4 flex justify-center items-center"
        >
          <FontAwesomeIcon icon={faGlobe} size="1x" />
          <span className="ml-2">joe.studio</span>{" "}
        </a>
        <a
          href="https://instagram.com/joehellow"
          target="_blank"
          className="text-center text-yellow-500 p-2 mb-4 flex justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-instagram"
            viewBox="0 0 16 16"
          >
            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />{" "}
          </svg>{" "}
          <span className="ml-2">joehellow</span>
        </a>
      </div>
      <p className="mb-4 text-lg text-gray-500">
        Dann bist du bei uns am richtigen Ort. In jeglicher Form bieten wir dir
        die beste Qualität auf kleinen Bühnen bis hin zu den grössten der Welt
        wie Madison Squar Garden. Unsere Angebote gehen von weltweiten
        Studiosessions bis hin zu FOH Engineer, hier noch all unsere Angebote:
      </p>

      <div className="mt-12 lg:col-span-2 lg:mt-0">
        <dl className="space-y-6 sm:grid sm:grid-flow-col sm:grid-cols-2 sm:grid-rows-4 sm:gap-x-6 sm:gap-y-10 sm:space-y-0 lg:gap-x-8">
          {features.map((feature) => (
            <div key={uuidv4()} className="relative">
              <dt>
                <CheckIcon
                  className="absolute h-6 w-6 text-yellow-500"
                  aria-hidden="true"
                />
                <p className="ml-9 text-lg font-medium leading-6 text-gray-900">
                  {feature.name}
                </p>
              </dt>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
